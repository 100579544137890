import React from 'react';
import Modal from 'react-modal';

import { api } from '../../../services/api';

import { AiOutlineClose } from "react-icons/ai";

function DeleteUserModal ({isOpen, onRequestClose, user}){
    async function deleteUser(){
        try {
            await api.delete(`/user/${user.id_user}`);
            onRequestClose(true, true);
        } catch (error) {
            let msg_error = undefined;
            
            if (error && error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData.error === 'string') {
                  msg_error = errorData.error;
                }
            }

            onRequestClose(true, false, msg_error);
        }
    }

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName="react-modal-overlay"
            className="react-modal-content react-modal-content-delete"
            isOpen={isOpen} 
            onRequestClose={onRequestClose}
        >
            <button 
                className="react-modal-close"
                onClick={onRequestClose} 
            >
                <AiOutlineClose />
            </button>
            <div className="react-modal-title">
                <h4 id="modal-title">Excluir Usuário</h4>
            </div>

            <div className="react-modal-body">
                <span className="modal-text">
                    Tem certeza que deseja excluir o usuário {user.name_user}?
                </span>
                <div className="react-modal-btn">
                    <button className="btn btn-blue" onClick={onRequestClose} >
                        Cancelar
                    </button>
                    <button className="btn btn-red" onClick={deleteUser}>
                        Excluir
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default DeleteUserModal;