import React from 'react';
import { api } from '../../../services/api';
import Modal from 'react-bootstrap/Modal'

import './style.css';


class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            endereco: '',
            contato: '',
            manual: '',
            apresentacao: '',
            link: '',
            waiting: true
        };

        this.onChange = this.onChange.bind(this)
        this.saveSettings = this.saveSettings.bind(this)
    }

    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    async saveSettings(e){
        
        const settings = {
            address: this.state.endereco, 
            contact: this.state.contato, 
            manual: this.state.manual, 
            presentation: this.state.apresentacao,
            link: this.state.link
        } 

        const response = await api.put('/settings', settings)

        this.props.onHide()
    }

    getForm() {
        return (
            <form>
                <div className="form-row">
                    <div className="form-group col-md-12 pr-3 pl-3">
                        <label>Endereço</label>
                        <input type="text" className="form-control" name="endereco" 
                        value={this.state.endereco} onChange={this.onChange}/>
                    </div>
                </div>
            
                <div className="form-row">
                    <div className="form-group col-md-6 pr-3 pl-3">
                        <label>Contato</label>
                        <input type="text" className="form-control" name="contato" 
                        value={this.state.contato} onChange={this.onChange}/>
                    </div>

                    <div className="form-group col-md-6 pr-3 pl-3">
                        <label>Link do manual</label>
                        <input type="text" className="form-control" name="manual" 
                        value={this.state.manual} onChange={this.onChange}/>
                    </div>
                </div>
                
                <div className="form-row">
                    <div className="form-group col-md-12 pr-3 pl-3">
                        <label>Apresentação</label>
                        <textarea  type="text" className="form-control" rows={8}  name="apresentacao" 
                        value={this.state.apresentacao} onChange={this.onChange}/>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-12 pr-3 pl-3">
                        <label>Link da página de validação</label>
                        <input type="text" className="form-control" name="link" 
                        value={this.state.link} onChange={this.onChange}/>
                    </div>
                </div>
            </form>
        );
    }
    
    static getDerivedStateFromProps(props, state){
        if(props.show){   
            if (state.waiting)       
                return ({...props.data, waiting: false})
            else
                return null
        }else{
            return ({waiting: true})
        }
    }


    render(){    
        return (
            <Modal
                className="main-settings"
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Configuração Geral do Sistema
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.getForm()}
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <button type="button" className="btn btn-secondary" onClick={this.props.onHide}>Sair</button>
                    <button type="button" className="btn btn-primary" onClick={this.saveSettings}>Salvar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Settings;
