import React from 'react';
import Settings from './Settings';
import Logs from './Logs';
import Users from './Users';
import Avas from './Avas';
import { api } from '../../services/api';
import Loader from '../Loader';

class System extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            dataSettings: {},
            showSettings: false,
            loadingSettings: false
        };
        this.showSettings = this.showSettings.bind(this)
        this.loadingSettings = this.loadingSettings.bind(this)
    }

    async showSettings(){
        const response = await api.get('/settings/')
        const settings = response.data.settings
        this.setState({
            dataSettings: {
                endereco: settings.address_settings,
                contato: settings.contact_settings,
                manual: settings.manual_settings,
                apresentacao: settings.presentation_settings,
                link: settings.link
            },
            showSettings: true,
            loadingSettings: false
        })
    }

    loadingSettings(){
        this.setState({
            loadingSettings: true
        })
        this.showSettings()
    }

    render(){

        if (this.props.getSecondaryOption() === "log"){
            return <Logs />;
        }else if (this.props.getSecondaryOption() === "usuarios"){
            return <Users />;
        }else if (this.props.getSecondaryOption() === "avas"){
            return <Avas />;
        }else{ 
            return (
                <React.Fragment>
                    <div className="main-home">
                        <div className="row">
                            <div className="column col-md-4">
                                <button className="btn" onClick={this.loadingSettings}>
                                    <div className="d-flex align-items-center">
                                        <div className="col-md-3 p-0">
                                            <i className="fas fa-tools"></i>
                                        </div>
                                        <div className="col-md-9 text">
                                            Configuração Geral do Sistema
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div className="column col-md-4">
                                <button className="btn" onClick={() => {
                                    this.props.setSecondaryOption("usuarios");
                                }}>
                                    <div className="d-flex align-items-center">
                                        <div className="col-md-2 p-0">
                                            <i className="far fa-address-card"></i>
                                        </div>
                                        <div className="col-md-10 text">
                                            Gerenciamento de Usuários
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div className="column col-md-4">
                                <button className="btn" onClick={() => {
                                    this.props.setSecondaryOption("avas");
                                }}>
                                    <div className="d-flex align-items-center">
                                        <div className="col-md-2 p-0">
                                            <i className="fas fa-cogs"></i>
                                        </div>
                                        <div className="col-md-10 text">
                                            Configurações dos AVAs
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {
                            /*
                                <div className="row">
                                    <div className="column col-md-4">
                                        <button className="btn" onClick={() => {
                                            this.props.setSecondaryOption("log");
                                        }}>
                                            <div className="d-flex align-items-center">
                                                <div className="col-md-3 p-0">
                                                    <i className="far fa-file-alt"></i>
                                                </div>
                                                <div className="col-md-9 text">
                                                    Logs do Sistema
                                                </div>
                                            </div>
                                        </button>
                                    </div> 
                                </div>
                            */
                        }
                    </div> 
                    <Settings 
                        data={this.state.dataSettings} 
                        show={this.state.showSettings}
                        onHide={() => this.setState({showSettings: false})
                    }/>

                    {this.state.loadingSettings ? <Loader/> : null}
                    
                </React.Fragment>
            
            )
        }
    }
}


export default System;
