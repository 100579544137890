import React from 'react';
import { api } from '../../services/api';
import Modal from 'react-bootstrap/Modal'

import './style.css';


class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            id: 0,
            nome: '',
            setor: '',
            email: '',
            senha: '',
            confirmSenha: '',
            waiting: true
        };

        this.onChange = this.onChange.bind(this)
        this.saveProfile = this.saveProfile.bind(this)
    }

    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    async saveProfile(e){
        
        const user = {
            id: this.state.id,
            email: this.state.email, 
            name: this.state.nome, 
            sector: this.state.setor
        }

        if (this.state.id > 0){
            if (this.state.senha !== this.state.confirmSenha){
                alert("Senha e confirmação de senha precisam ter o mesmo valor")
            }else{
                const userPassword = {
                    id: this.state.id,
                    password: this.state.senha
                }

                if (this.state.senha !== ''){
                    const result1 = await api.post(`/user/update`, userPassword);
                }
                
                const result2 = await api.put(`/user`, user);
                this.props.loading()
                this.props.onHide()
            }
        }else{
            this.props.onHide()
        }        
    }

    getForm() {
        return (
            <form>
                <div className="form-row m-0">
                    <div className="form-group col-md-12 pr-3 pl-3">
                        <label>Nome</label>
                        <input type="text" className="form-control" name="nome" 
                        value={this.state.nome} onChange={this.onChange}/>
                    </div>
                </div>
            
                <div className="form-row m-0">
                    <div className="form-group col-md-6 pr-3 pl-3">
                        <label>Setor</label>
                        <input type="text" className="form-control" name="setor" 
                        value={this.state.setor} onChange={this.onChange}/>
                    </div>

                    <div className="form-group col-md-6 pr-3 pl-3">
                        <label>Email</label>
                        <input type="email" className="form-control" name="email" 
                        value={this.state.email} onChange={this.onChange}/>
                    </div>
                </div>
                
                <div className="form-row m-0">
                    <div className="form-group col-md-6 pr-3 pl-3">
                        <label>Senha</label>
                        <input type="password" className="form-control" name="senha" 
                        value={this.state.senha} onChange={this.onChange}/>
                    </div>

                    <div className="form-group col-md-6 pr-3 pl-3">
                        <label>Confirmação de Senha</label>
                        <input type="password" className="form-control" name="confirmSenha" 
                        value={this.state.confirmSenha} onChange={this.onChange}/>
                    </div>
                </div>

            </form>
        );
    }

    static getDerivedStateFromProps(props, state){
        if(props.show){   
            if (state.waiting)       
                return ({...props.data, waiting: false})
            else
                return null
        }else{
            return ({waiting: true, senha: '', confirmSenha: '',})
        }
    }

    render(){
        return (
            <Modal
                className="main-profile"
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Perfil do Usuário
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.getForm()}
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <button type="button" className="btn btn-secondary" onClick={this.props.onHide}>Sair</button>
                    <button type="button" className="btn btn-primary" onClick={this.saveProfile}>Salvar</button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default Profile;
