import React from 'react';
import { api } from '../../../services/api';

import NewProgramModal from '../../ProgramModal/NewProgramModal';
import DeleteProgramModal from '../../ProgramModal/DeleteProgramModal';
import EditProgramModal from '../../ProgramModal/EditProgramModal';
import Alert from '../../Alert';

import { FaTrash, FaPencilAlt, FaFileDownload } from 'react-icons/fa';
import FileSaver from 'file-saver';
import Loader from '../../Loader';

class Programs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            programs: [],
            avas: [],
            isNewProgramModalOpen: false,
            isDeleteProgramModalOpen: false,
            isEditProgramModalOpen: false,
            programSelected: {},
            avaSelected: 0,
            typeAlert: '',
            messageAlert: '',
            visibleAlert: false,
            loadingDocument: false
        };
        
        this.listPrograms = this.listPrograms.bind(this);
        this.listAvas = this.listAvas.bind(this);
        this.closeAlert = this.closeAlert.bind(this);

        this.generateTestDocument = this.generateTestDocument.bind(this);

        this.handleOpenNewProgramModal = this.handleOpenNewProgramModal.bind(this);
        this.handleCloseNewProgramModal = this.handleCloseNewProgramModal.bind(this);

        this.handleOpenDeleteProgramModal = this.handleOpenDeleteProgramModal.bind(this);
        this.handleCloseDeleteProgramModal = this.handleCloseDeleteProgramModal.bind(this);

        this.handleOpenEditProgramModal = this.handleOpenEditProgramModal.bind(this);
        this.handleCloseEditProgramModal = this.handleCloseEditProgramModal.bind(this);
    }

    componentDidMount() {
       this.listPrograms(); 
       this.listAvas();           
    }

    closeAlert(){
        this.setState({visibleAlert: false});
    }
    
    handleOpenNewProgramModal(){
        this.setState({isNewProgramModalOpen: true});
    }

    handleCloseNewProgramModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isNewProgramModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Programa cadastrado com sucesso!",
                    visibleAlert: true
                });
                this.listPrograms();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível cadastrar um novo Programa. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenDeleteProgramModal(program){
        this.setState({
            programSelected: program, 
            isDeleteProgramModalOpen: true
        });
    }

    handleCloseDeleteProgramModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isDeleteProgramModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Programa excluído com sucesso!",
                    visibleAlert: true,
                    programs: this.state.programs.filter((item) => item.id_program !== this.state.programSelected.id_program)
                });
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível deletar o Programa. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenEditProgramModal(program){
        this.setState({
            programSelected: program,
            isEditProgramModalOpen: true
        });
    }

    handleCloseEditProgramModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isEditProgramModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Dados do Programa atualizados com sucesso!",
                    visibleAlert: true
                });
                this.listPrograms();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível atualizar os dados do Programa. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    async listPrograms(){
        try {
            const response = await api.get('/program/');
            this.setState({programs: response.data.programs});    
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível listar os programas",
                visibleAlert: true
            });
        }
    }

    async listAvas(){
        try {
            const response = await api.get('/ava');
            this.setState({
                avas: [{id_ava: 0, name_ava: "Todos os AVAs"}, ...response.data.avas]
            });    
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi carregar a lista de Avas",
                visibleAlert: true
            });
        }
    }

    async generateTestDocument(code_ava_program, id_ava){
        this.setState({ loadingDocument: true, visibleAlert: false });
        
        try {
            const response = await api.get(
                `/certificate/program/test?id_ava=${id_ava}&code_ava_program=${code_ava_program}`, 
                { responseType: 'blob' }
            );

            const blob = new Blob([response.data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, "certificado_programa_teste.pdf");
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível fazer o Download do documento",
                visibleAlert: true
            });
        }

        this.setState({ loadingDocument: false });
    }
    
    render() {
        return (
            <div className="row m-0">
                <div className={this.state.visibleAlert === true ? "row m-0 area-alert" : "hide-area"}>    
                    <Alert type={this.state.typeAlert} message={this.state.messageAlert} closeAlert={this.closeAlert} />
                </div>
                <div className="navigation">
                    Configurações/ Programas
                </div>

                <div className="row action-panel">
                    <div className="col-md-4">
                        <select className="form-select" onChange={(event) => this.setState({ avaSelected: parseInt(event.target.value)})}>
                            {this.state.avas.map((ava) => (
                                <option 
                                    className="select-options" 
                                    key={"ava"+ava.id_ava} 
                                    value={ava.id_ava}
                                >
                                    {ava.name_ava}
                                </option>
                            ))} 
                        </select>
                    </div>
                    <div className="col-md-5"></div>
                    <div className="btn-area col-md-3">
                        <button className="btn btn-primary d-block" onClick={this.handleOpenNewProgramModal}>
                            + Novo Programa
                        </button>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>
                                <tr className='titles'>
                                    <th>Programa</th>
                                    <th>Carga Horária</th>
                                    <th>Quantidade de Cursos</th>
                                    <th>AVA</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.programs.map((program) => (
                                    (this.state.avaSelected === program.id_ava || this.state.avaSelected === 0) && 
                                        <tr className='rows' key={"program-id-"+program.id_program}>
                                            <td>{program.Modality.name_modality}</td>
                                            <td>{program.ch_program ? program.ch_program : 0}</td>
                                            <td>{program.quant_courses}</td>
                                            <td>{program.Ava.name_ava}</td>
                                            <td className="settings">
                                                <div className="settings-icons">
                                                    <span 
                                                        className="icon edit" title="Exemplo de Certificado" 
                                                        onClick={() => this.generateTestDocument(program.code_ava_program, program.id_ava)}
                                                    >
                                                        <FaFileDownload />
                                                    </span>
                                                    <span className="icon edit" title="Editar Programa" onClick={() => this.handleOpenEditProgramModal(program)}>
                                                        <FaPencilAlt />
                                                    </span>
                                                    <span className="icon delete" title="Excluir Programa" onClick={() => this.handleOpenDeleteProgramModal(program)}>
                                                        <FaTrash />
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    
                                ))} 
                            </tbody>
                        </table>
                        {this.state.loadingDocument ? <Loader /> : null}
                    </div>
                    {this.state.isNewProgramModalOpen &&
                        <NewProgramModal
                            id="newProgramModal"
                            isOpen={this.state.isNewProgramModalOpen} 
                            onRequestClose={this.handleCloseNewProgramModal}
                            avas={this.state.avas.slice(1)}
                        />
                    }

                    {this.state.isDeleteProgramModalOpen &&
                        <DeleteProgramModal
                            id="deleteProgramModal"
                            isOpen={this.state.isDeleteProgramModalOpen} 
                            onRequestClose={this.handleCloseDeleteProgramModal}
                            program={this.state.programSelected}
                        />
                    }

                    {this.state.isEditProgramModalOpen &&
                        <EditProgramModal
                            id="editProgramModal"
                            isOpen={this.state.isEditProgramModalOpen} 
                            onRequestClose={this.handleCloseEditProgramModal}
                            avas={this.state.avas.slice(1)}
                            program={this.state.programSelected}
                        />
                    }
                </div>
                
            </div>
        );
    }
}

export default Programs;