import React from 'react'
import Header from '../Header';
import Footer from '../Footer';
import { api } from '../../services/api';

import './styles.css' 

import { FaChevronRight, FaUserCircle } from 'react-icons/fa';
import Profile from '../Profile';



class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            option: "",
            dataUser: {},
            showProfile: false,
            idUser: 0,
            admin: false
        };
        this.onChange = this.onChange.bind(this)
        this.selectOption = this.selectOption.bind(this)
        this.loadingDataUser = this.loadingDataUser.bind(this)
    }


    async componentDidMount(){
        const tokenJSON = await localStorage.getItem('@UNASUS:certification-token')

        if (tokenJSON !== null){
            const tokenOBJ = JSON.parse(tokenJSON)
            this.setState({idUser: tokenOBJ.data.user.id_user, admin: tokenOBJ.data.user.admin_user})
            this.loadingDataUser()
        }
        
        this.setState({option: this.props.option})      
    }

    async loadingDataUser(){
        if (this.state.idUser > 0){
            
            const response = await api.get(`/user/${this.state.idUser}`)
            const user = response.data.user
            this.setState({
                dataUser: {
                    id: this.state.idUser,
                    nome: user.name_user,
                    email: user.email_user,
                    setor: user.sector_user
                }
            })
        }   
    }

    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }

    selectOption(option){
        this.setState({option: option})
        this.props.setOption(option)
    }
  
    render() {
        return (
            <div className="container-menu p-0">
                <Header/>
                
                <div className="wrapper">
                    <nav className="sidebar">
                        <div className="sidebar-header">
                            <button className="btn-item d-flex align-items-center justify-content-center" 
                            type="button" onClick={() => this.setState({showProfile: true})}>
                                <FaUserCircle className="mr-2" size="29"/>
                                {this.state.dataUser.nome}
                            </button>
                        </div>

                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <div className={this.state.option==="documentos" ? "div-btn active" : "div-btn"}>
                                    <button type="button" onClick={() => {
                                        this.selectOption("documentos");
                                        this.props.setSecondaryOption("");
                                    }} className="btn-item">
                                        Documentos
                                        <FaChevronRight className="ml-1" size="23"/>
                                    </button>
                                </div>
                            </li>

                            <li className="nav-item">
                                <div className={this.state.option==="configuracoes" ? "div-btn active" : "div-btn"}>
                                    <button type="button" onClick={() => {
                                        this.selectOption("configuracoes");
                                        this.props.setSecondaryOption("");
                                    }} className="btn-item">
                                        Configurações
                                        <FaChevronRight className="ml-1" size="23"/>
                                    </button>
                                </div>
                            </li>

                            {this.state.admin ?
                            <li className="nav-item">
                                <div className={this.state.option==="sistema" ? "div-btn active" : "div-btn"}>
                                    <button type="button" onClick={() => {
                                            this.selectOption("sistema");
                                            this.props.setSecondaryOption("");
                                        }} className="btn-item">
                                        Sistema
                                        <FaChevronRight className="ml-1" size="23"/>
                                    </button>
                                </div>
                            </li> : null}

                            <li className="nav-item">
                                <div className={this.state.option==="sair" ? "div-btn active" : "div-btn"}>
                                    <button className="btn-item" type="button" onClick={() => this.selectOption("sair")}>
                                        Sair
                                    </button>
                                </div>
                            </li>                            

                        </ul>
                    </nav>  

                    <div className="content">
                        <div className="container">
                            {this.props.content}
                        </div>
                    </div>
                    
                </div>

                <Footer/>
                <Profile
                    data={this.state.dataUser} 
                    show={this.state.showProfile}
                    onHide={() => this.setState({showProfile: false})}
                    loading={this.loadingDataUser}
                />
            </div>
        );
    }
}

export default Menu