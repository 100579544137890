import React from "react";

import "./styles.css";

import Menu from "../../components/Menu";
import System from "../../components/System";
import Documents from "../../components/Documents";
import Configuration from "../../components/Configuration"
import { Redirect } from "react-router-dom";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      password: "",
      viewPassword: false,
      option: "documentos",
      optionSecundary: "",
    };

    this.onChange = this.onChange.bind(this);
    this.setOption = this.setOption.bind(this);
    this.selectOptionSecundary = this.selectOptionSecundary.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  setOption(value) {
    this.setState({ option: value });
    this.setState({ optionSecundary: "" });
  }

  selectOptionSecundary(optionSecundary) {
    this.setState({ optionSecundary: optionSecundary });
  }

  getRegister() {
    return (
      <Configuration
        getSecondaryOption={() => this.state.selectOptionSecundary}
        setSecondaryOption={(x) => this.setState({ selectOptionSecundary: x })}
      />
    );
  }

  getSystem() {
    return (
      <System
        getSecondaryOption={() => this.state.selectOptionSecundary}
        setSecondaryOption={(x) => this.setState({ selectOptionSecundary: x })}
      />
    );
  }

  getDocuments() {
    return (
      <Documents
        getSecondaryOption={() => this.state.selectOptionSecundary}
        setSecondaryOption={(x) => this.setState({ selectOptionSecundary: x })}
      />
    );
  }

  getRender() {
    if (this.state.option === "documentos") {
      return this.getDocuments();
    } else if (this.state.option === "configuracoes") {
      return this.getRegister();
    } else if (this.state.option === "sistema") {
      return this.getSystem();
    } else if (this.state.option === "sair") {
      localStorage.clear();
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }

  render() {
    return (
      <Menu
        content={this.getRender()}
        setOption={(value) => this.setOption(value)}
        option={this.state.option}
        setSecondaryOption={(x) => this.setState({ selectOptionSecundary: x })}
      />
    );
  }
}

export default Home;
