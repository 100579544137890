import React from 'react'
import { Link } from 'react-router-dom';

import { api } from '../../services/api';
import { withRouter } from 'react-router-dom';

import './styles.css'
import '../../styles/Validation&Login.css'

import LogoUFMA from '../../assets/ufma.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
            viewPassword: false,
            errorMessage: '',
            address: ''
        };
        this.onChange = this.onChange.bind(this)
        this.login = this.login.bind(this)
        this.viewPassword = this.viewPassword.bind(this)
    }

    async componentDidMount() {
        if (localStorage.getItem('@UNASUS:certification-token') !== null){
            this.props.history.push('/home')
        }else{
            const response = await api.get('/settings/')
            const settings = response.data.settings
            this.setState({
                address: settings.address_settings,
            })
        }
    }

    saveData(user) {
        localStorage.setItem('@UNASUS:certification-token', JSON.stringify(user))
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    async login(e) {
        e.preventDefault();

        if (!this.state.user.length || this.state.user.length === 0)
            return

        try {
            this.setState({errorMessage: ""})

            const credentials = {
                email: this.state.user,
                password: this.state.password
            } 

            const response = await api.post('/user/authenticate', credentials)
            //const user = response.data.user
            //alert(JSON.stringify(user))

            this.saveData(response)

            this.props.history.push('/home')

        } catch (err) {
            console.log(JSON.stringify(err))
            this.setState({errorMessage: "Usuário e/ou senha inválidos."})
        }
    }

    viewPassword() {
        this.setState({ viewPassword: !this.state.viewPassword })
    }

    render() {
        return (
            <div className="container-login background-blue p-0 grid-container">

                <div className="row m-0">
                    <div className="col-md-6 block-1">

                        <div className="welcome text-center">Seja Bem Vindo</div>
                        <div className="title text-center">SAITE Certificação</div>
                    </div>
                    <div className="col-md-6 block-2">
                        <div className="icon"><i className="fas fa-adjust"></i></div>
                        
                        <div className="card">
                            <div className="card-body">
                                <div className="title">Acesso</div>
                                <hr />
                                <form onSubmit={this.login}>
                                    <input type="text" className="input d-block" placeholder="Usuário"
                                        value={this.state.user} name="user" onChange={this.onChange}></input>

                                    <div className="input d-flex justify-content-between align-items-center">
                                        <input type={this.state.viewPassword ? "text" : "password"} placeholder="Senha" value={this.state.password} name="password" onChange={this.onChange} />
                                        <span className="p-viewer" onClick={() => this.setState({ viewPassword: !this.state.viewPassword })}>
                                            {this.state.viewPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                    <button type="submit" className="btn btn-primary d-block acessar">Acessar</button>
                                    {/*<Link className="forgotPassword" to="/">Esqueceu a senha?</Link><br/>*/}

                                    <span className="error-msg">{this.state.errorMessage || ""}</span>
                                </form>
                            </div>

                        </div>

                    </div>
                </div>


                <div className="row m-0 block-3">
                    <img className="logo-ufma" src={LogoUFMA} alt="" />
                    <div className="address">
                        Endereço: {this.state.address}
                    </div>
                </div>

                <footer className="row m-0">
                    © 2021 UNA-SUS/UFMA - Todos os direitos reservados.
                </footer>

            </div>
        );
    }
}

export default withRouter(Login)
