import React from 'react'

import './styles.css' 

import { BiSearchAlt2 } from 'react-icons/bi';


class Header extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this)
    }

    onChange(e){
        this.setState({[e.target.name]: e.target.value})
    }
  
    render() {
        return (
            <div className="header">
                SAITE Certificação
                {/*<div className="search d-flex justify-content-between align-items-center">
                    <input type="text" placeholder="Pesquisar" onChange={this.onChange}/>
                    <button> <BiSearchAlt2 size="30"/> </button>
                </div>
                <button> 
                    <i className="icon-contraste fas fa-adjust"></i>
                </button>*/}
            </div>
        );
    }
}

export default Header