import React from 'react';
import { api } from '../../../services/api';

import NewTemplateModal from '../../TemplateModal/NewTemplateModal';
import DeleteTemplateModal from '../../TemplateModal/DeleteTemplateModal';
import EditTemplateModal from '../../TemplateModal/EditTemplateModal';
import Alert from '../../Alert';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';

class Templates extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            templates: [],
            isNewTemplateModalOpen: false,
            isDeleteTemplateModalOpen: false,
            isEditTemplateModalOpen: false,
            programs: [],
            programSelected: 0,
            avas: [],
            avaSelected: 0,
            templateSelected: {},
            typeTemplateSelected: "all",
            typeAlert: '',
            messageAlert: '',
            visibleAlert: false
        };
        
        this.listTemplates = this.listTemplates.bind(this);
        this.listAvas = this.listAvas.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.onChangeAva = this.onChangeAva.bind(this);
        this.validateTemplate = this.validateTemplate.bind(this);


        this.handleOpenNewTemplateModal = this.handleOpenNewTemplateModal.bind(this);
        this.handleCloseNewTemplateModal = this.handleCloseNewTemplateModal.bind(this);

        this.handleOpenDeleteTemplateModal = this.handleOpenDeleteTemplateModal.bind(this);
        this.handleCloseDeleteTemplateModal = this.handleCloseDeleteTemplateModal.bind(this);
    
        this.handleOpenEditTemplateModal = this.handleOpenEditTemplateModal.bind(this);
        this.handleCloseEditTemplateModal = this.handleCloseEditTemplateModal.bind(this);
    }

    componentDidMount() {
        this.listTemplates();
        this.listAvas();
    }

    closeAlert(){
        this.setState({visibleAlert: false});
    }
    
    handleOpenNewTemplateModal(){
        this.setState({isNewTemplateModalOpen: true});
    }

    handleCloseNewTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isNewTemplateModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Template cadastrado com sucesso!",
                    visibleAlert: true
                });
                this.listTemplates();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível cadastrar um novo Template. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenDeleteTemplateModal(template){
        this.setState({
            templateSelected: template,
            isDeleteTemplateModalOpen: true
        });
    }

    handleCloseDeleteTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isDeleteTemplateModalOpen: false});
        if(operation === true){
            if(successOperation === true){
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Template excluído com sucesso!",
                    visibleAlert: true,
                    templates: this.state.templates.filter((item) => item.id_template_c !== this.state.templateSelected.id_template_c)
                });
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível deletar o Template de Certificado. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    handleOpenEditTemplateModal(template){
        this.setState({
            templateSelected: template,
            isEditTemplateModalOpen: true
        });
    }

    handleCloseEditTemplateModal(operation = false, successOperation = false, messageAlert = undefined){
        this.setState({isEditTemplateModalOpen: false});
        if(operation === true) {
            if(successOperation === true) {
                this.setState({
                    typeAlert: "success",
                    messageAlert: "Dados do Template atualizados com sucesso!",
                    visibleAlert: true
                });
                this.listTemplates();
            }else{
                this.setState({
                    typeAlert: "error",
                    messageAlert: messageAlert !== undefined ? messageAlert : "Não foi possível atualizar os dados do Template. Tente novamente!",
                    visibleAlert: true
                });
            }
        }
    }

    validateTemplate(t_courses, t_programs){
        if (this.state.avaSelected === 0)
            return true;
        
        if (t_courses && t_courses.id_ava){
            if (t_courses.id_ava.includes(this.state.avaSelected)){
                if(this.state.programSelected === 0)
                    return true;
                if(t_courses.id_program.includes(this.state.programSelected))
                    return true;
            }
        }

        if (t_programs && t_programs.id_ava){
            if (t_programs.id_ava.includes(this.state.avaSelected)){
                if(this.state.programSelected === 0)
                    return true;
                if(t_programs.id_program.includes(this.state.programSelected))
                    return true;
            }
        }
        return false;
    }

    async onChangeAva(value){
        this.setState({ 
            avaSelected: value,
            programSelected: 0,
            programs: []
        });
        if(parseInt(value) !== 0){
            const response = await api.get(`/program/list/${parseInt(value)}`);
            this.setState({ programs: [{id_program: 0, name_program: "Todos os Programas do AVA"}, ...response.data.programs]});
        }   
    }

    async listAvas(){
        try {
            const response = await api.get('/ava');
            this.setState({ avas: [{id_ava: 0, name_ava: "Todos os AVAs"}, ...response.data.avas]});
            this.setState({ programs: [{id_program: 0, name_program: "Todos os Programas"}]});
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível listar os os AVAs",
                visibleAlert: true
            });
        }
    }

    async listTemplates(){
        try {
            const response = await api.get('/templatec');
            this.setState({templates: response.data.templates});   
        } catch (error) {
            this.setState({
                typeAlert: "error",
                messageAlert: "Não foi possível listar os Templates de Certificado",
                visibleAlert: true
            });
        }
    }

    
    render() {
        return (
            <div className="row m-0">
                <div className={this.state.visibleAlert === true ? "row m-0 area-alert" : "hide-area"}>    
                    <Alert type={this.state.typeAlert} message={this.state.messageAlert} closeAlert={this.closeAlert} />
                </div>

                <div className="navigation">
                    Configurações/ Templates de Certificado
                </div>             
                
                <div className="row action-panel">
                    <div className="col-md-4 select">
                        <select className="form-select" onChange={(event) => this.onChangeAva(parseInt(event.target.value))}>
                            {this.state.avas.map((ava) => (
                                <option 
                                    className="select-options"
                                    key={"ava"+ava.id_ava} 
                                    value={ava.id_ava}
                                >
                                    {ava.name_ava}
                                </option>
                            ))} 
                        </select>
                    </div>

                    <div className="col-md-4 select">
                        <select className="form-select" onChange={(event) => this.setState({ programSelected: parseInt(event.target.value)})}>
                            {this.state.programs.map((program) => (
                                <option 
                                    className="select-options"
                                    key={"program"+program.id_program} 
                                    value={program.id_program}
                                >
                                    {program.id_program !== 0 && program.Modality?.name_modality}
                                    {program.id_program === 0 && program.name_program}
                                </option>
                            ))} 
                        </select>
                    </div>
                    
                    <div className="btn-area col-md-4">
                        <button className="btn btn-primary d-block" onClick={this.handleOpenNewTemplateModal}>
                            + Novo Template
                        </button>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="table">
                        <table>
                            <thead>
                                <tr className='titles'>
                                    <th>Nome</th>
                                    <th>Mensagem</th>
                                    <th>Capa</th>
                                    <th>Cores</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.templates.map((template) => ( this.validateTemplate(template.t_courses, template.t_programs) && 
                                    <tr className='rows' key={"template-c-id-"+template.id_template_c}>
                                        <td>{template.name_template}</td>
                                        <td>{template.message_template}</td>
                                        <td>    
                                            {template.cover_template !== undefined && template.cover_template !== null &&
                                                <img src={process.env.REACT_APP_BACKEND_URL + '/' + template.cover_template} alt="Imagem de Capa do Template de Certificado" width="200"></img>
                                            }
                                        </td>
                                        <td>
                                            <div className="colors-template">
                                                <input 
                                                    type="color" key={"t"+template.id_template_c+"c0"} disabled
                                                    value={template.colors_template.split(';')[0]} className="row"
                                                /> 
                                                <input 
                                                    type="color" key={"t"+template.id_template_c+"c1"} disabled
                                                    value={template.colors_template.split(';')[1]} className="row"
                                                /> 
                                                <input 
                                                    type="color" key={"t"+template.id_template_c+"c2"} disabled
                                                    value={template.colors_template.split(';')[2]} className="row"
                                                /> 
                                                <input 
                                                    type="color" key={"t"+template.id_template_c+"c3"} disabled
                                                    value={template.colors_template.split(';')[3]} className="row"
                                                /> 
                                            </div>
                                            
                                        </td>
                                        <td className="settings">
                                            <div className="settings-icons">
                                                <span className="icon edit" title="Editar Template" onClick={() => this.handleOpenEditTemplateModal(template)}>
                                                    <FaPencilAlt />
                                                </span>
                                                <span className="icon delete" title="Excluir Template" onClick={() => this.handleOpenDeleteTemplateModal(template)}>
                                                    <FaTrash />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>     
                    </div>
                </div>

                {this.state.isNewTemplateModalOpen &&
                    <NewTemplateModal
                        id="newTemplateModal"
                        isOpen={this.state.isNewTemplateModalOpen} 
                        onRequestClose={this.handleCloseNewTemplateModal}
                    />
                }

                {this.state.isDeleteTemplateModalOpen &&
                    <DeleteTemplateModal
                        id="deleteTemplateModal"
                        isOpen={this.state.isDeleteTemplateModalOpen}
                        onRequestClose={this.handleCloseDeleteTemplateModal}
                        template={this.state.templateSelected}
                    />
                }

                {this.state.isEditTemplateModalOpen &&
                    <EditTemplateModal
                        id="editTemplateModal"
                        isOpen={this.state.isEditTemplateModalOpen}
                        onRequestClose={this.handleCloseEditTemplateModal}
                        template={this.state.templateSelected}
                    />    
                }

            </div>
        );
    }
}

export default Templates;