import React from 'react';
import ProgramCertificate from './ProgramCertificate';
import CourseCertificate from './CourseCertificate';
import Declaration from './Declaration';
import SchoolRecord from './SchoolRecord';

class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
    }

    render(){
        if (this.props.getSecondaryOption() === "courseCertificate"){
            return <CourseCertificate />;
        }else if (this.props.getSecondaryOption() === "programCertificate"){
                return <ProgramCertificate />;
        }else if (this.props.getSecondaryOption() === "declaration"){
            return <Declaration/>;
        }else if (this.props.getSecondaryOption() === "school_record"){
            return <SchoolRecord/>;
        }else { 
            return (
                <React.Fragment>
                    <div className="main-home">
                        <div className="row">
                            <div className="column col-md-4">
                                <button className="btn" onClick={() => {
                                    this.props.setSecondaryOption("declaration");
                                }}>
                                    <div className="d-flex align-items-center">
                                        <div className="col-md-3 p-0">
                                            <i className="far fa-file-alt"></i>
                                        </div>
                                        <div className="col-md-9 text">
                                            Emissão de Declaração
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div className="column col-md-4">
                                <button className="btn" onClick={() => {
                                    this.props.setSecondaryOption("school_record");
                                }}>
                                    <div className="d-flex align-items-center">
                                        <div className="col-md-2 p-0">
                                            <i className="far fa-file-alt"></i>
                                        </div>
                                        <div className="col-md-10 text">
                                            Emissão de Histórico
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div className="column col-md-4">
                                <button className="btn" onClick={() => {
                                    this.props.setSecondaryOption("courseCertificate");
                                }}>
                                    <div className="d-flex align-items-center">
                                        <div className="col-md-3 p-0">
                                            <i className="far fa-file-alt"></i>
                                        </div>
                                        <div className="col-md-9 text">
                                            Emissão de Certificado de Curso
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column col-md-4">
                                <button className="btn" onClick={() => {
                                    this.props.setSecondaryOption("programCertificate");
                                }}>
                                    <div className="d-flex align-items-center">
                                        <div className="col-md-3 p-0">
                                            <i className="far fa-file-alt"></i>
                                        </div>
                                        <div className="col-md-9 text">
                                            Emissão de Certificado de Programa
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div> 
                    
                </React.Fragment>
            
            )
        }
    }
}


export default Documents;
